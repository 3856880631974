<template lang="pug">
  .settings
    RestrictedCountryBanner(v-if="restrictedBannerVisible", @clickClose="closeRestrictedBanner")
    .settings_menu
      v-layout.wrapper
        router-link.logo(to="/settings/personal", tag="div")
          Logo(serviceName="ecosystem")
        v-tabs(
          slot="extension",
          v-model="tab",
          color="transparent")
          v-tab(to="personal")
            span {{ $t('common.personal') }}
          //v-tab(v-if="!isNewUser" to="notifications")
          //  span {{ $t('common.notifications') }}
          v-tab(to="security")
            span {{ $t('common.security') }}
          v-tab(v-if="isKYCEnabled && countryId", to="verification")
            span {{ $t('common.verification') }}
          v-tab(v-if="isKYCEnabled", to="api")
            span API
          v-tab(href=`${process.env.VUE_APP_KICKEX}`)
            span {{ $t('common.backToKickEx') }}
        //a.chat(v-if="user.is_email_confirmed", href=`${process.env.VUE_APP_MESSENGER}`)
        //  SvgChat
        //  .chat_count(v-if="unreadMessagesNumber") {{ unreadMessagesNumberStr }}
        //router-link.chat(v-else, to="/settings/verification")
        //  SvgChat
        //  .chat_count(v-if="unreadMessagesNumber") {{ unreadMessagesNumberStr }}
        //  span {{ $t('notifications.chatVerifyEmailHint') }}
        //.settings__apps
        //  ServicesNavigationMenu(v-if="!isNewUser" :services="services", :clickable="isServicesClickable")
        .settings__logout(@click="logout")
          SvgLogout
        .settings__close(v-if="referrer", @click="goBackToReferrer")
          SvgPlus
    .email_confirm(v-if="!user.is_email_confirmed && $route.name !== 'settings-verification'")
      v-layout.wrapper(align-center)
        strong {{ $t('confirmEmailBanner.title') }}
        SvgAlert
        span {{ $t('confirmEmailBanner.description') }}
        Countdown.countdown(
          v-if="confirmationSentTimestamp && countdownActive",
          :timer="fiveMinInMs",
          :timerStart="confirmationSentTimestamp",
          :digitsOnly="true",
          @expired="countdownActive = false")
        .link(v-else, @click="handleResendLink") {{ $t('confirmEmailBanner.link') }}
    transition(name="fade", mode="out-in")
      router-view(
        :emailConfirmed="user.is_email_confirmed || false",
        :verifyAgain="verifyAgain",
        @verifyAgain="restartVerification",
        @acceptedTerms="showReminder = false",
        @clickSubmit="submitForm")
    Footer(v-if="$route.name === 'settings-verification'")
</template>

<script>
import { mapState } from 'vuex';
import ServicesNavigationMenu from '@/components/ServicesNavigationMenu';
import RestrictedCountryBanner from '@/components/RestrictedCountryBanner';
import SvgPlus from '@/components/svg/SvgPlus';
import SvgChat from '@/components/svg/SvgChat';
import SvgLogout from '@/components/svg/SvgLogout';
import SvgAlert from '@/components/svg/SvgAlert';
import store from '@/store/store';
import Logo from '@/components/Logo';
import CONSTANTS from '@/constants';
import { USER_LOGOUT } from '@/store/modules/user/types';
import Footer from '@/components/TheFooter';
import Countdown from '@/components/Countdown';

export default {
  components: {
    ServicesNavigationMenu,
    RestrictedCountryBanner,
    SvgPlus,
    SvgChat,
    SvgLogout,
    SvgAlert,
    Logo,
    Footer,
    Countdown
  },
  data() {
    return {
      tab: null,
      verifyAgain: false,
      loggingOut: false,
      isKYCEnabled: process.env.VUE_APP_KYC === 'true',
      showReminder: true,
      services: CONSTANTS.SERVICES_MENU.filter(i => i.visible),
      unreadMessagesNumber: 0,
      restrictedBannerVisible: false,
      isServicesClickable: true,
      countryId: null,
      fiveMinInMs: 5 * 60 * 1000,
      countdownActive: false
    };
  },
  methods: {
    submitForm() { this.showReminder = true; },
    restartVerification() {
      this.verifyAgain = true;
      if (this.$route.name !== 'settings-verification') this.$router.push('/settings/verification');
    },
    goBackToReferrer() {
      this.$router.push({ name: 'Redirect', params: { path: this.referrer } });
    },
    logout() {
      if (this.loggingOut) return;

      this.loggingOut = true;

      this.$store.dispatch('user/deleteSessionGroup', localStorage.getItem('session_id'))
        .then(() => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('session_id');
          localStorage.removeItem('referrer');

          this.$store.commit(`user/${USER_LOGOUT}`);
          this.$router.push({ name: 'signin' });
        })
        .catch(err => console.log(err))
        .finally(() => { this.loggingOut = false; });
    },
    closeRestrictedBanner() {
      this.restrictedBannerVisible = false;
      localStorage.setItem('restricted_banner_shown', this.user.email);
    },
    async handleResendLink() {
      if (this.countdownActive) return;

      this.countdownActive = true;

      await this.$store.dispatch('user/resendConfirmationEmail');
    }
  },
  computed: {
    ...mapState('user', [
      'confirmationSentTimestamp'
    ]),
    user() { return this.$store.state.user; },
    serviceId() { return localStorage.getItem('service_id') || 0; },
    referrer() { return this.$store.state.user.referrer; },
    serviceLink() {
      switch (+this.serviceId) {
        case 1: return process.env.VUE_APP_KICKICO;
        case 3: return process.env.VUE_APP_KICKEX;
        case 5: return process.env.VUE_APP_WALLET;
        case 4:
        default:
          return process.env.VUE_APP_KICKREF;
      }
    },
    unreadMessagesNumberStr() {
      if (this.unreadMessagesNumber === 1000) return '1K';

      if (this.unreadMessagesNumber > 1000) {
        const str = this.unreadMessagesNumber.toString();

        return `${str.charAt(0)}K+`;
      }

      return this.unreadMessagesNumber;
    }
    // chatLink() {
    //   if (!this.user.is_email_confirmed) {
    //     return process.env.VUE_APP_MESSENGER;
    //   }

    //   return '/settings/verification';
    // }
    // isNewUser() {
    //   return this.user.registration_date > +new Date(2024, 5) && this.user.kyc_status === 0;
    // }
  },
  mounted() {
    this.$eventHub.$on('WSunreadMessages', count => {
      this.unreadMessagesNumber = count;
    });

    if (this.user.registration_country_id && this.user.is_country_restricted && localStorage.getItem('restricted_banner_shown') !== this.user.email) {
      this.restrictedBannerVisible = true;
    }

    if (!this.user.registration_country_id || !this.user.last_name || !this.user.first_name) {
      this.isServicesClickable = false;
    }
  },
  watch: {
    user: {
      handler(val) {
        if (!val) return;

        this.countryId = val.registration_country_id;

        if (!val.registration_country_id) return;

        if (val.is_country_restricted && localStorage.getItem('restricted_banner_shown') !== val.email) {
          this.restrictedBannerVisible = true;
        }

        if (val.registration_country_id && !val.is_country_restricted && this.restrictedBannerVisible) {
          this.restrictedBannerVisible = false;
        }

        if (val.registration_country_id) {
          this.isServicesClickable = true;
        }
      },
      deep: true
    },
    confirmationSentTimestamp(val) {
      if (val) this.countdownActive = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (store.state.user.kyc_status === 3) vm.showReminder = false;

      vm.countryId = store.state.user.registration_country_id;
    });
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .settings {
    &_menu {
      position: sticky;

      &::before {
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: $light-theme-line;
        content: '';
      }

      .wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: auto;
        max-width: 1140px;
        padding: 0 20px;

        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
          padding-top: 20px;
        }
      }

      .logo {
        cursor: pointer;
        margin-right: 20px;
      }

      .chat {
        display: inline-block;
        padding-top: 5px;
        text-transform: none !important;
        position: relative;
        margin-right: 25px;

        &:hover {
          span { display: inline-block; }
        }

        &_count {
          display: inline-block;
          position: absolute;
          top: 0;
          right: -5px;
          font-size: 8px;
          background: $error-color;
          line-height: normal;
          font-variant-numeric: tabular-nums;
          text-align: center;
          color: $white;
          font-weight: 600;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          padding-top: 2px;
        }

        span {
          display: none;
          width: 245px;
          font-size: .75rem;
          padding: 7px 12px;
          position: absolute;
          top: 30px;
          left: 0;
          z-index: 10;
          background: $grey-dark5;
          border-radius: 4px;
          color: $white;
        }
      }
    }

    &__title {
      font-size: 20px;
      color: $sub-black;
      font-weight: bold;
      margin: auto 0;
      width: 200px;
      text-align: left;
    }

    &__close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      outline: none;
      transform: rotate(45deg);
      margin: auto 0 auto auto;
      opacity: 0.54;
      cursor: pointer;
    }

    &__apps { margin-right: 23px; }

    &__logout {
      margin: 5px 20px 0 0;

      svg {
        width: 23px;
        height: 23px;
      }
    }

    &__services_list {
      .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        font-weight: 700 !important;
        background-color: transparent;
        transition: all .3s $easing;
        cursor: pointer;

        span { color: $orange !important; }

        &:hover { background-color: $blue-lighter-transparent-1 !important; }
      }

      .warn { color: $error-color; }
      .highlight { color: $sub-black; }
    }

    .ecosystem {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-weight: 800;
      margin-right: 20px;
      font-size: 25px;
      color: $black;
      letter-spacing: .05em;

      & > svg {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        margin-right: 16px;
      }

      span { color: $orange; }
    }

    @media screen and (max-width: 1024px) {
      &__close {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .chat {
        position: absolute;
        top: 20px;
        right: 90px;
      }

      &__apps {
        position: absolute;
        top: 22px;
        right: 40px;
      }

      &__logout {
        position: absolute;
        top: 20px;
        right: 0;
      }
    }
  }

  .email_confirm {
    background: $error-color;
    color: $white;
    font-size: 12px;
    font-weight: 500;
    text-align: left;

    & > .layout {
      min-height: 80px;
    }

    strong:not(.countdown) {
      font-size: 20px;
      margin-right: 20px;
    }

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      margin-right: 50px;

      ::v-deep path { fill: $white; }
    }

    .link {
      margin-left: 10px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  // Transition animation
  .fade-enter-active,
  .fade-leave-active {
    transition: all .3s $easing;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
